import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';

import { SearchItem } from '../model/search-item';
import { ApiDefaultService } from './api-default.service';

@Injectable({
  providedIn: 'root'
})
export class ApiUserSearchService extends ApiDefaultService {
    public getSearch(search : string) : Observable<any>{
        return this.httpClient.get<any>(this._getUrl() + "/search?search="+search, this._httpOptions())
            .pipe(
              retry(1),
              catchError(this.handleError)
            );
    }
}
