import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';

import { User } from '../model/user';
import { ApiDefaultService } from './api-default.service';

@Injectable({
  providedIn: 'root'
})
export class ApiUserService extends ApiDefaultService {

    public me() : Observable<User>{
        return this.httpClient.get<User>(this._getUrl() + "/me", this._httpOptions())
        .pipe(
              retry(1),
              catchError(this.handleError)
        );
    }

    refreshAccessToken(): Observable<any> {
      return this.httpClient.post<any>(this._baseUrl() + "/refresh", {
        token: this._userDataService.auth.refresh_token,
      });
    }

    update(user, avatar): Observable<User> {
        const uploadData = new FormData();
        if (avatar !== undefined){
            uploadData.append('avatar', avatar, avatar.name);
        }
        uploadData.append('body', JSON.stringify(user));
      return this.httpClient.post<User>(this._getUrl() + '/profile', uploadData, this._httpOptionsFormData())
      .pipe(
        retry(0),
        catchError(this.handleError)
      )
    }
}
