import { Injectable } from '@angular/core';

import { Auth } from '../model/auth';
import { User } from '../model/user';
import { Config } from '../model/config';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
    public auth : Auth;
    public user: User;
    public config: Config;
    public settings: any = {datatables:{length:10}};
    public dashboard: String;

    constructor() { 

    }
}
