import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { ApiUserService } from './api-user.service';
import { UserDataService } from './user-data.service';

import { User } from '../model/user';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    public url: string;

    constructor(
        private _apiUserService: ApiUserService,
        private _userDataService: UserDataService
    ) { }

    isAuthenticated(): boolean {
        if (!this._userDataService.auth && !environment.production) {
          const user = localStorage.getItem('token');
          if (user) {
            this._userDataService.user = JSON.parse(user);
            this._userDataService.auth = JSON.parse(localStorage.getItem('auth'));
            this._userDataService.dashboard = JSON.parse(localStorage.getItem('dashboard'));
          }
        }
        return this._userDataService.auth !== undefined && this._userDataService.auth !== null;

    }

    login(auth) : Observable<User>{
        this._userDataService.auth = auth;

        return this._apiUserService.me().pipe(map(user => {
            this._userDataService.user = user;
            this._userDataService.dashboard = this.getDashboard();
            if (!environment.production) {
              localStorage.setItem('token', JSON.stringify(user));
              localStorage.setItem('auth', JSON.stringify(auth));
              localStorage.setItem('dashboard', JSON.stringify(this._userDataService.dashboard));
            }
            
            return user;
         }))
        ;
    }

    hasRole(role) : boolean{
        return this._userDataService.user.roles.indexOf(role) !== -1
    }

    getDashboard() : string{
        if (this.url){
            return this.url;
        }

        if (this.hasRole('ROLE_ADMIN')){
            return '/admin';
        }

        if (this.hasRole('ROLE_CUSTOMER')){
            return '/customer';
        }

        return '/';
    }

    logout(){
        localStorage.removeItem('token');
        localStorage.removeItem('auth');
        localStorage.removeItem('dashboard');
        this._userDataService.auth = null;
        this._userDataService.user = null;
    }
}
