import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';

import { ApiDefaultService } from './api-default.service';

import { Config } from '../model/config';


@Injectable({
  providedIn: 'root'
})
export class ApiConfigService extends ApiDefaultService{

    public get() : Observable<Config>{
        return this.httpClient.get<Config>(this._getUrl() + "/config", this._httpOptions())
        .pipe(
              retry(1),
              catchError(this.handleError)
        );
    }

    getConfig() {
      return this._userDataService.config
        ? of(this._userDataService.config) // wrap cached value for consistent return value
        : this.get()
            .pipe(map(config => {
                this._userDataService.config = config;
                return config;
         }))

        ;
    }
}