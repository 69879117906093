import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

import { User } from '../model/user';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class ApiDefaultService {
    constructor(
        protected httpClient: HttpClient,
        protected _userDataService: UserDataService) { 
    }

    // Error handling 
    protected handleError(error) {
       let errorMessage = '';
       if(error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
            //console.log(error);
         // Get server-side error
         //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}\nBody: ${error.error.errors)}`;
         errorMessage = `${error.error.errors.join('<br>')}`
       }
       //window.alert(errorMessage);
       return throwError(errorMessage);
    }

    protected _httpOptions(){
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+this._userDataService.auth.access_token
            })
          }; 
    }

    protected _getUrl(){
        return environment.apiUrl + "/user";
    }

    protected _baseUrl() {
      return environment.apiUrl;
    }

    protected _httpOptionsFormData(){
        return {
            headers: new HttpHeaders({
                'Authorization': 'Bearer '+this._userDataService.auth.access_token
            })
          }; 
    }
}
